@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Poppins%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700&family=Source+Sans+Pro%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B1%2C300%3B1%2C400%3B1%2C600%3B1%2C700&display=swap");

.plasmic_plasmic_tokens__BR2hY {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_plasmic_default_styles__8xTHK,
.plasmic_plasmic_default_styles__8xTHK:where(.plasmic_global__032720251400041620250600_override__XhuAD) {
  --mixin-0Ju1CT0tordN_font-family: "Inter", sans-serif;
  --mixin-0Ju1CT0tordN_font-size: 16px;
  --mixin-0Ju1CT0tordN_font-weight: 400;
  --mixin-0Ju1CT0tordN_font-style: normal;
  --mixin-0Ju1CT0tordN_color: #535353;
  --mixin-0Ju1CT0tordN_text-align: left;
  --mixin-0Ju1CT0tordN_text-transform: none;
  --mixin-0Ju1CT0tordN_line-height: 1.5;
  --mixin-0Ju1CT0tordN_letter-spacing: normal;
  --mixin-0Ju1CT0tordN_white-space: pre-wrap;
  --mixin-0Ju1CT0tordN_user-select: text;
  --mixin-0Ju1CT0tordN_text-decoration-line: none;
  --mixin-0Ju1CT0tordN_text-overflow: clip;
  --mixin-VU5Bw0Dmeh07_color: #000000;
  --mixin-VU5Bw0Dmeh07_font-weight: 700;
  --mixin-VU5Bw0Dmeh07_font-size: 64px;
  --mixin-VU5Bw0Dmeh07_line-height: 1;
  --mixin-VU5Bw0Dmeh07_letter-spacing: -1px;
  --mixin-VU5Bw0Dmeh07_white-space: pre-wrap;
  --mixin-SYXLVHLUUr6g_color: #000000;
  --mixin-SYXLVHLUUr6g_font-size: 48px;
  --mixin-SYXLVHLUUr6g_font-weight: 700;
  --mixin-SYXLVHLUUr6g_letter-spacing: -0.5px;
  --mixin-SYXLVHLUUr6g_line-height: 1.1;
  --mixin-SYXLVHLUUr6g_white-space: pre-wrap;
  --mixin-6qp3i6O7TihM_color: #0070f3;
  --mixin-6qp3i6O7TihM_white-space: pre-wrap;
  --mixin-9QYC-f9h6HOZ_color: #000000;
  --mixin-9QYC-f9h6HOZ_font-size: 32px;
  --mixin-9QYC-f9h6HOZ_font-weight: 600;
  --mixin-9QYC-f9h6HOZ_line-height: 1.2;
  --mixin-9QYC-f9h6HOZ_white-space: pre-wrap;
  --mixin-XBvudDVwrYgv_color: #000000;
  --mixin-XBvudDVwrYgv_font-size: 24px;
  --mixin-XBvudDVwrYgv_font-weight: 600;
  --mixin-XBvudDVwrYgv_line-height: 1.3;
  --mixin-XBvudDVwrYgv_white-space: pre-wrap;
  --mixin-hI8Y1xlsNiZp_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-hI8Y1xlsNiZp_border-bottom-color: #dddddd;
  --mixin-hI8Y1xlsNiZp_border-bottom-style: solid;
  --mixin-hI8Y1xlsNiZp_border-bottom-width: 1px;
  --mixin-hI8Y1xlsNiZp_border-left-color: #dddddd;
  --mixin-hI8Y1xlsNiZp_border-left-style: solid;
  --mixin-hI8Y1xlsNiZp_border-left-width: 1px;
  --mixin-hI8Y1xlsNiZp_border-right-color: #dddddd;
  --mixin-hI8Y1xlsNiZp_border-right-style: solid;
  --mixin-hI8Y1xlsNiZp_border-right-width: 1px;
  --mixin-hI8Y1xlsNiZp_border-top-color: #dddddd;
  --mixin-hI8Y1xlsNiZp_border-top-style: solid;
  --mixin-hI8Y1xlsNiZp_border-top-width: 1px;
  --mixin-hI8Y1xlsNiZp_border-bottom-left-radius: 3px;
  --mixin-hI8Y1xlsNiZp_border-bottom-right-radius: 3px;
  --mixin-hI8Y1xlsNiZp_border-top-left-radius: 3px;
  --mixin-hI8Y1xlsNiZp_border-top-right-radius: 3px;
  --mixin-hI8Y1xlsNiZp_font-family: "Inconsolata";
  --mixin-hI8Y1xlsNiZp_padding-bottom: 1px;
  --mixin-hI8Y1xlsNiZp_padding-left: 4px;
  --mixin-hI8Y1xlsNiZp_padding-right: 4px;
  --mixin-hI8Y1xlsNiZp_padding-top: 1px;
  --mixin-hI8Y1xlsNiZp_white-space: pre-wrap;
  --mixin-B6AcL8lfukz5_border-left-color: #dddddd;
  --mixin-B6AcL8lfukz5_border-left-style: solid;
  --mixin-B6AcL8lfukz5_border-left-width: 3px;
  --mixin-B6AcL8lfukz5_color: #888888;
  --mixin-B6AcL8lfukz5_padding-left: 10px;
  --mixin-B6AcL8lfukz5_white-space: pre-wrap;
  --mixin-biqACFlnh8jO_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-biqACFlnh8jO_border-bottom-color: #dddddd;
  --mixin-biqACFlnh8jO_border-bottom-style: solid;
  --mixin-biqACFlnh8jO_border-bottom-width: 1px;
  --mixin-biqACFlnh8jO_border-left-color: #dddddd;
  --mixin-biqACFlnh8jO_border-left-style: solid;
  --mixin-biqACFlnh8jO_border-left-width: 1px;
  --mixin-biqACFlnh8jO_border-right-color: #dddddd;
  --mixin-biqACFlnh8jO_border-right-style: solid;
  --mixin-biqACFlnh8jO_border-right-width: 1px;
  --mixin-biqACFlnh8jO_border-top-color: #dddddd;
  --mixin-biqACFlnh8jO_border-top-style: solid;
  --mixin-biqACFlnh8jO_border-top-width: 1px;
  --mixin-biqACFlnh8jO_border-bottom-left-radius: 3px;
  --mixin-biqACFlnh8jO_border-bottom-right-radius: 3px;
  --mixin-biqACFlnh8jO_border-top-left-radius: 3px;
  --mixin-biqACFlnh8jO_border-top-right-radius: 3px;
  --mixin-biqACFlnh8jO_font-family: "Inconsolata";
  --mixin-biqACFlnh8jO_padding-bottom: 3px;
  --mixin-biqACFlnh8jO_padding-left: 6px;
  --mixin-biqACFlnh8jO_padding-right: 6px;
  --mixin-biqACFlnh8jO_padding-top: 3px;
  --mixin-biqACFlnh8jO_white-space: pre-wrap;
  --mixin-vzv9q_yjxHCl_display: flex;
  --mixin-vzv9q_yjxHCl_flex-direction: column;
  --mixin-vzv9q_yjxHCl_align-items: stretch;
  --mixin-vzv9q_yjxHCl_justify-content: flex-start;
  --mixin-vzv9q_yjxHCl_list-style-position: outside;
  --mixin-vzv9q_yjxHCl_padding-left: 40px;
  --mixin-vzv9q_yjxHCl_position: relative;
  --mixin-vzv9q_yjxHCl_list-style-type: disc;
  --mixin-vzv9q_yjxHCl_white-space: pre-wrap;
  --mixin-6gX8E4R6OO93_display: flex;
  --mixin-6gX8E4R6OO93_flex-direction: column;
  --mixin-6gX8E4R6OO93_align-items: stretch;
  --mixin-6gX8E4R6OO93_justify-content: flex-start;
  --mixin-6gX8E4R6OO93_list-style-position: outside;
  --mixin-6gX8E4R6OO93_padding-left: 40px;
  --mixin-6gX8E4R6OO93_position: relative;
  --mixin-6gX8E4R6OO93_list-style-type: decimal;
  --mixin-6gX8E4R6OO93_white-space: pre-wrap;
  --mixin-w6G9u46-_7cD_color: #000000;
  --mixin-w6G9u46-_7cD_font-size: 20px;
  --mixin-w6G9u46-_7cD_font-weight: 600;
  --mixin-w6G9u46-_7cD_line-height: 1.5;
  --mixin-w6G9u46-_7cD_white-space: pre-wrap;
  --mixin-FdbOv-2fAP3R_color: #000000;
  --mixin-FdbOv-2fAP3R_font-size: 16px;
  --mixin-FdbOv-2fAP3R_font-weight: 600;
  --mixin-FdbOv-2fAP3R_line-height: 1.5;
  --mixin-FdbOv-2fAP3R_white-space: pre-wrap;
  --mixin-azR2A3XE26Kp_color: #3291ff;
  --mixin-azR2A3XE26Kp_white-space: pre-wrap;
  --mixin-Wb4LIdadifVK_white-space: pre-wrap;
  --mixin-J2t0NuQm2LFY_white-space: pre-wrap;
}

:where(.plasmic_all__JDh1_) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic___wab_expr_html_text__mTflW *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_img__yMaVy) {
  display: inline-block;
}
:where(.plasmic___wab_expr_html_text__mTflW img) {
  white-space: inherit;
}

:where(.plasmic_li__TijbR) {
  display: list-item;
}
:where(.plasmic___wab_expr_html_text__mTflW li) {
  white-space: inherit;
}

:where(.plasmic_span__b5TZ1) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_input__ADf3u) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic___wab_expr_html_text__mTflW input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_textarea__olBwz) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic___wab_expr_html_text__mTflW textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_button__zClm1) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic___wab_expr_html_text__mTflW button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_code___AXLw) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_pre__l_lb4) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_p__5WIPI) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_h1__Muyc_) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h2__OvK4c) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h3__0q8UH) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h4__MwdFn) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h5__wR32r) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_h6__KKZtc) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_address__IDaFt) {
  font-style: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_a__Hwy3y) {
  color: inherit;
}
:where(.plasmic___wab_expr_html_text__mTflW a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_ol__eh9f6) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic___wab_expr_html_text__mTflW ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_ul__yiPi8) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic___wab_expr_html_text__mTflW ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_select__bz2PI) {
  padding: 2px 6px;
}
:where(.plasmic___wab_expr_html_text__mTflW select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_plasmic_default__component_wrapper__TZXOP {
  display: grid;
}
.plasmic_plasmic_default__inline__y2IX8 {
  display: inline;
}
.plasmic_plasmic_page_wrapper__2wG4d {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_plasmic_page_wrapper__2wG4d > * {
  height: auto !important;
}
.plasmic___wab_expr_html_text__mTflW {
  white-space: normal;
}
:where(.plasmic_root_reset__LaJux) {
  font-family: var(--mixin-0Ju1CT0tordN_font-family);
  font-size: var(--mixin-0Ju1CT0tordN_font-size);
  font-weight: var(--mixin-0Ju1CT0tordN_font-weight);
  font-style: var(--mixin-0Ju1CT0tordN_font-style);
  color: var(--mixin-0Ju1CT0tordN_color);
  text-align: var(--mixin-0Ju1CT0tordN_text-align);
  text-transform: var(--mixin-0Ju1CT0tordN_text-transform);
  line-height: var(--mixin-0Ju1CT0tordN_line-height);
  letter-spacing: var(--mixin-0Ju1CT0tordN_letter-spacing);
  white-space: var(--mixin-0Ju1CT0tordN_white-space);
}

:where(.plasmic_root_reset__LaJux) h1:where(.plasmic_h1__Muyc_),
h1:where(.plasmic_root_reset__LaJux.plasmic_h1__Muyc_),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) h1,
:where(.plasmic_root_reset_tags__pbI5q) h1,
h1:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-VU5Bw0Dmeh07_color);
  font-weight: var(--mixin-VU5Bw0Dmeh07_font-weight);
  font-size: var(--mixin-VU5Bw0Dmeh07_font-size);
  line-height: var(--mixin-VU5Bw0Dmeh07_line-height);
  letter-spacing: var(--mixin-VU5Bw0Dmeh07_letter-spacing);
}

:where(.plasmic_root_reset__LaJux) h2:where(.plasmic_h2__OvK4c),
h2:where(.plasmic_root_reset__LaJux.plasmic_h2__OvK4c),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) h2,
:where(.plasmic_root_reset_tags__pbI5q) h2,
h2:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-SYXLVHLUUr6g_color);
  font-size: var(--mixin-SYXLVHLUUr6g_font-size);
  font-weight: var(--mixin-SYXLVHLUUr6g_font-weight);
  letter-spacing: var(--mixin-SYXLVHLUUr6g_letter-spacing);
  line-height: var(--mixin-SYXLVHLUUr6g_line-height);
}

:where(.plasmic_root_reset__LaJux) a:where(.plasmic_a__Hwy3y),
a:where(.plasmic_root_reset__LaJux.plasmic_a__Hwy3y),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) a,
:where(.plasmic_root_reset_tags__pbI5q) a,
a:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-6qp3i6O7TihM_color);
}

:where(.plasmic_root_reset__LaJux) h3:where(.plasmic_h3__0q8UH),
h3:where(.plasmic_root_reset__LaJux.plasmic_h3__0q8UH),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) h3,
:where(.plasmic_root_reset_tags__pbI5q) h3,
h3:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-9QYC-f9h6HOZ_color);
  font-size: var(--mixin-9QYC-f9h6HOZ_font-size);
  font-weight: var(--mixin-9QYC-f9h6HOZ_font-weight);
  line-height: var(--mixin-9QYC-f9h6HOZ_line-height);
}

:where(.plasmic_root_reset__LaJux) h4:where(.plasmic_h4__MwdFn),
h4:where(.plasmic_root_reset__LaJux.plasmic_h4__MwdFn),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) h4,
:where(.plasmic_root_reset_tags__pbI5q) h4,
h4:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-XBvudDVwrYgv_color);
  font-size: var(--mixin-XBvudDVwrYgv_font-size);
  font-weight: var(--mixin-XBvudDVwrYgv_font-weight);
  line-height: var(--mixin-XBvudDVwrYgv_line-height);
}

:where(.plasmic_root_reset__LaJux) code:where(.plasmic_code___AXLw),
code:where(.plasmic_root_reset__LaJux.plasmic_code___AXLw),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) code,
:where(.plasmic_root_reset_tags__pbI5q) code,
code:where(.plasmic_root_reset_tags__pbI5q) {
  background: #f8f8f8;
  font-family: var(--mixin-hI8Y1xlsNiZp_font-family);
  border-radius: var(--mixin-hI8Y1xlsNiZp_border-top-left-radius)
    var(--mixin-hI8Y1xlsNiZp_border-top-right-radius)
    var(--mixin-hI8Y1xlsNiZp_border-bottom-right-radius)
    var(--mixin-hI8Y1xlsNiZp_border-bottom-left-radius);
  padding: var(--mixin-hI8Y1xlsNiZp_padding-top)
    var(--mixin-hI8Y1xlsNiZp_padding-right)
    var(--mixin-hI8Y1xlsNiZp_padding-bottom)
    var(--mixin-hI8Y1xlsNiZp_padding-left);
  border-top: var(--mixin-hI8Y1xlsNiZp_border-top-width)
    var(--mixin-hI8Y1xlsNiZp_border-top-style)
    var(--mixin-hI8Y1xlsNiZp_border-top-color);
  border-right: var(--mixin-hI8Y1xlsNiZp_border-right-width)
    var(--mixin-hI8Y1xlsNiZp_border-right-style)
    var(--mixin-hI8Y1xlsNiZp_border-right-color);
  border-bottom: var(--mixin-hI8Y1xlsNiZp_border-bottom-width)
    var(--mixin-hI8Y1xlsNiZp_border-bottom-style)
    var(--mixin-hI8Y1xlsNiZp_border-bottom-color);
  border-left: var(--mixin-hI8Y1xlsNiZp_border-left-width)
    var(--mixin-hI8Y1xlsNiZp_border-left-style)
    var(--mixin-hI8Y1xlsNiZp_border-left-color);
}

:where(.plasmic_root_reset__LaJux) blockquote:where(.plasmic_blockquote__0C8xk),
blockquote:where(.plasmic_root_reset__LaJux.plasmic_blockquote__0C8xk),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) blockquote,
:where(.plasmic_root_reset_tags__pbI5q) blockquote,
blockquote:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-B6AcL8lfukz5_color);
  padding-left: var(--mixin-B6AcL8lfukz5_padding-left);
  border-left: var(--mixin-B6AcL8lfukz5_border-left-width)
    var(--mixin-B6AcL8lfukz5_border-left-style)
    var(--mixin-B6AcL8lfukz5_border-left-color);
}

:where(.plasmic_root_reset__LaJux) pre:where(.plasmic_pre__l_lb4),
pre:where(.plasmic_root_reset__LaJux.plasmic_pre__l_lb4),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) pre,
:where(.plasmic_root_reset_tags__pbI5q) pre,
pre:where(.plasmic_root_reset_tags__pbI5q) {
  background: #f8f8f8;
  font-family: var(--mixin-biqACFlnh8jO_font-family);
  border-radius: var(--mixin-biqACFlnh8jO_border-top-left-radius)
    var(--mixin-biqACFlnh8jO_border-top-right-radius)
    var(--mixin-biqACFlnh8jO_border-bottom-right-radius)
    var(--mixin-biqACFlnh8jO_border-bottom-left-radius);
  padding: var(--mixin-biqACFlnh8jO_padding-top)
    var(--mixin-biqACFlnh8jO_padding-right)
    var(--mixin-biqACFlnh8jO_padding-bottom)
    var(--mixin-biqACFlnh8jO_padding-left);
  border-top: var(--mixin-biqACFlnh8jO_border-top-width)
    var(--mixin-biqACFlnh8jO_border-top-style)
    var(--mixin-biqACFlnh8jO_border-top-color);
  border-right: var(--mixin-biqACFlnh8jO_border-right-width)
    var(--mixin-biqACFlnh8jO_border-right-style)
    var(--mixin-biqACFlnh8jO_border-right-color);
  border-bottom: var(--mixin-biqACFlnh8jO_border-bottom-width)
    var(--mixin-biqACFlnh8jO_border-bottom-style)
    var(--mixin-biqACFlnh8jO_border-bottom-color);
  border-left: var(--mixin-biqACFlnh8jO_border-left-width)
    var(--mixin-biqACFlnh8jO_border-left-style)
    var(--mixin-biqACFlnh8jO_border-left-color);
}

:where(.plasmic_root_reset__LaJux) ul:where(.plasmic_ul__yiPi8),
ul:where(.plasmic_root_reset__LaJux.plasmic_ul__yiPi8),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) ul,
:where(.plasmic_root_reset_tags__pbI5q) ul,
ul:where(.plasmic_root_reset_tags__pbI5q) {
  display: var(--mixin-vzv9q_yjxHCl_display);
  flex-direction: var(--mixin-vzv9q_yjxHCl_flex-direction);
  align-items: var(--mixin-vzv9q_yjxHCl_align-items);
  justify-content: var(--mixin-vzv9q_yjxHCl_justify-content);
  list-style-position: var(--mixin-vzv9q_yjxHCl_list-style-position);
  padding-left: var(--mixin-vzv9q_yjxHCl_padding-left);
  position: var(--mixin-vzv9q_yjxHCl_position);
  list-style-type: var(--mixin-vzv9q_yjxHCl_list-style-type);
  flex-column-gap: var(--mixin-vzv9q_yjxHCl_flex-column-gap);
}

:where(.plasmic_root_reset__LaJux) ol:where(.plasmic_ol__eh9f6),
ol:where(.plasmic_root_reset__LaJux.plasmic_ol__eh9f6),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) ol,
:where(.plasmic_root_reset_tags__pbI5q) ol,
ol:where(.plasmic_root_reset_tags__pbI5q) {
  display: var(--mixin-6gX8E4R6OO93_display);
  flex-direction: var(--mixin-6gX8E4R6OO93_flex-direction);
  align-items: var(--mixin-6gX8E4R6OO93_align-items);
  justify-content: var(--mixin-6gX8E4R6OO93_justify-content);
  list-style-position: var(--mixin-6gX8E4R6OO93_list-style-position);
  padding-left: var(--mixin-6gX8E4R6OO93_padding-left);
  position: var(--mixin-6gX8E4R6OO93_position);
  list-style-type: var(--mixin-6gX8E4R6OO93_list-style-type);
  flex-column-gap: var(--mixin-6gX8E4R6OO93_flex-column-gap);
}

:where(.plasmic_root_reset__LaJux) h5:where(.plasmic_h5__wR32r),
h5:where(.plasmic_root_reset__LaJux.plasmic_h5__wR32r),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) h5,
:where(.plasmic_root_reset_tags__pbI5q) h5,
h5:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-w6G9u46-_7cD_color);
  font-size: var(--mixin-w6G9u46-_7cD_font-size);
  font-weight: var(--mixin-w6G9u46-_7cD_font-weight);
  line-height: var(--mixin-w6G9u46-_7cD_line-height);
}

:where(.plasmic_root_reset__LaJux) h6:where(.plasmic_h6__KKZtc),
h6:where(.plasmic_root_reset__LaJux.plasmic_h6__KKZtc),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) h6,
:where(.plasmic_root_reset_tags__pbI5q) h6,
h6:where(.plasmic_root_reset_tags__pbI5q) {
  color: var(--mixin-FdbOv-2fAP3R_color);
  font-size: var(--mixin-FdbOv-2fAP3R_font-size);
  font-weight: var(--mixin-FdbOv-2fAP3R_font-weight);
  line-height: var(--mixin-FdbOv-2fAP3R_line-height);
}

:where(.plasmic_root_reset__LaJux) a:where(.plasmic_a__Hwy3y):hover,
a:where(.plasmic_root_reset__LaJux.plasmic_a__Hwy3y):hover,
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) a:hover,
:where(.plasmic_root_reset_tags__pbI5q) a:hover,
a:where(.plasmic_root_reset_tags__pbI5q):hover {
  color: var(--mixin-azR2A3XE26Kp_color);
}

:where(.plasmic_root_reset__LaJux) li:where(.plasmic_li__TijbR),
li:where(.plasmic_root_reset__LaJux.plasmic_li__TijbR),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) li,
:where(.plasmic_root_reset_tags__pbI5q) li,
li:where(.plasmic_root_reset_tags__pbI5q) {
}

:where(.plasmic_root_reset__LaJux) p:where(.plasmic_p__5WIPI),
p:where(.plasmic_root_reset__LaJux.plasmic_p__5WIPI),
:where(.plasmic_root_reset__LaJux .plasmic___wab_expr_html_text__mTflW) p,
:where(.plasmic_root_reset_tags__pbI5q) p,
p:where(.plasmic_root_reset_tags__pbI5q) {
}

